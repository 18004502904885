define("discourse/plugins/discourse-chat-integration/admin/models/rule", ["exports", "@glimmer/tracking", "discourse/models/category", "discourse/models/rest", "I18n"], function (_exports, _tracking, _category, _rest, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Rule extends _rest.default {
    static #_ = dt7948.g(this.prototype, "type", [_tracking.tracked], function () {
      return "normal";
    });
    #type = (dt7948.i(this, "type"), void 0);
    static #_2 = dt7948.g(this.prototype, "category_id", [_tracking.tracked], function () {
      return null;
    });
    #category_id = (dt7948.i(this, "category_id"), void 0);
    static #_3 = dt7948.g(this.prototype, "tags", [_tracking.tracked], function () {
      return null;
    });
    #tags = (dt7948.i(this, "tags"), void 0);
    static #_4 = dt7948.g(this.prototype, "channel_id", [_tracking.tracked], function () {
      return null;
    });
    #channel_id = (dt7948.i(this, "channel_id"), void 0);
    static #_5 = dt7948.g(this.prototype, "filter", [_tracking.tracked], function () {
      return "watch";
    });
    #filter = (dt7948.i(this, "filter"), void 0);
    static #_6 = dt7948.g(this.prototype, "error_key", [_tracking.tracked], function () {
      return null;
    });
    #error_key = (dt7948.i(this, "error_key"), void 0);
    available_types = [{
      id: "normal",
      name: _I18n.default.t("chat_integration.type.normal")
    }, {
      id: "group_message",
      name: _I18n.default.t("chat_integration.type.group_message")
    }, {
      id: "group_mention",
      name: _I18n.default.t("chat_integration.type.group_mention")
    }];
    get available_filters() {
      const available = [];
      const provider = this.channel.provider;
      if (provider === "slack") {
        available.push({
          id: "thread",
          name: _I18n.default.t("chat_integration.filter.thread"),
          icon: "chevron-right"
        });
      }
      available.push({
        id: "watch",
        name: _I18n.default.t("chat_integration.filter.watch"),
        icon: "exclamation-circle"
      }, {
        id: "follow",
        name: _I18n.default.t("chat_integration.filter.follow"),
        icon: "circle"
      }, {
        id: "tag_added",
        name: _I18n.default.t("chat_integration.filter.tag_added"),
        icon: "tag"
      }, {
        id: "mute",
        name: _I18n.default.t("chat_integration.filter.mute"),
        icon: "times-circle"
      });
      return available;
    }
    get category() {
      const categoryId = this.category_id;
      if (categoryId) {
        return _category.default.findById(categoryId);
      } else {
        return false;
      }
    }
    get filterName() {
      return _I18n.default.t(`chat_integration.filter.${this.filter}`);
    }
    updateProperties() {
      return this.getProperties(["type", "category_id", "group_id", "tags", "filter"]);
    }
    createProperties() {
      return this.getProperties(["type", "channel_id", "category_id", "group_id", "tags", "filter"]);
    }
  }
  _exports.default = Rule;
});