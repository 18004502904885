define("discourse/plugins/discourse-chat-integration/admin/components/modal/test-integration", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _ajax, _ajaxError, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    {{on "submit" this.send}}
    @title={{i18n "chat_integration.test_modal.title"}}
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
    @flashType="success"
    @tagName="form"
    id="chat_integration_test_modal"
  >
    <:body>
      <table>
        <tbody>
          <tr class="input">
            <td class="label">
              <label for="channel">
                {{i18n "chat_integration.test_modal.topic"}}
              </label>
            </td>
            <td>
              <ChooseTopic @selectedTopicId={{this.topicId}} />
            </td>
          </tr>
        </tbody>
      </table>
    </:body>
  
    <:footer>
      <ConditionalLoadingSpinner @condition={{this.loading}}>
        <DButton
          @action={{this.send}}
          @label="chat_integration.test_modal.send"
          @disabled={{not this.topicId}}
          type="submit"
          id="send-test"
          class="btn-primary btn-large"
        />
        <DButton
          @action={{@closeModal}}
          @label="chat_integration.test_modal.close"
          class="btn-large"
        />
      </ConditionalLoadingSpinner>
    </:footer>
  </DModal>
  */
  {
    "id": "9hpQrgcn",
    "block": "[[[8,[39,0],[[24,1,\"chat_integration_test_modal\"],[4,[38,2],[\"submit\",[30,0,[\"send\"]]],null]],[[\"@title\",\"@closeModal\",\"@flash\",\"@flashType\",\"@tagName\"],[[28,[37,1],[\"chat_integration.test_modal.title\"],null],[30,1],[30,0,[\"flash\"]],\"success\",\"form\"]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,\"table\"],[12],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n        \"],[10,\"tr\"],[14,0,\"input\"],[12],[1,\"\\n          \"],[10,\"td\"],[14,0,\"label\"],[12],[1,\"\\n            \"],[10,\"label\"],[14,\"for\",\"channel\"],[12],[1,\"\\n              \"],[1,[28,[35,1],[\"chat_integration.test_modal.topic\"],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n            \"],[8,[39,3],null,[[\"@selectedTopicId\"],[[30,0,[\"topicId\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],[[24,1,\"send-test\"],[24,0,\"btn-primary btn-large\"],[24,4,\"submit\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"send\"]],\"chat_integration.test_modal.send\",[28,[37,6],[[30,0,[\"topicId\"]]],null]]],null],[1,\"\\n      \"],[8,[39,5],[[24,0,\"btn-large\"]],[[\"@action\",\"@label\"],[[30,1],\"chat_integration.test_modal.close\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"on\",\"choose-topic\",\"conditional-loading-spinner\",\"d-button\",\"not\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/components/modal/test-integration.hbs",
    "isStrictMode": false
  });
  class TestIntegration extends _component2.default {
    static #_ = dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    });
    #loading = (dt7948.i(this, "loading"), void 0);
    static #_2 = dt7948.g(this.prototype, "flash", [_tracking.tracked]);
    #flash = (dt7948.i(this, "flash"), void 0);
    static #_3 = dt7948.g(this.prototype, "topicId", [_tracking.tracked]);
    #topicId = (dt7948.i(this, "topicId"), void 0);
    async send() {
      this.loading = true;
      try {
        await (0, _ajax.ajax)("/admin/plugins/chat-integration/test", {
          data: {
            channel_id: this.args.model.channel.id,
            topic_id: this.topicId
          },
          type: "POST"
        });
        this.loading = false;
        this.flash = _I18n.default.t("chat_integration.test_modal.success");
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    static #_4 = dt7948.n(this.prototype, "send", [_object.action]);
  }
  _exports.default = TestIntegration;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TestIntegration);
});